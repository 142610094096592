<template>
  <div id="admin-comment">
    <comment :sign="0"></comment>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import comment from "@/components/comment/comment.vue"

export default defineComponent({
   components: {
     comment
   }
})
</script>

<style lang="scss">

</style>